import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import UnorderedList from "~/components/List/Unordered";
import Button from "~/components/button";
import Accordion from "~/components/accordion/accordion";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={5}
        lesson={"Lesson 2"}
        color={"ms"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 5</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>Later in this lesson, you will analyze information from two clinical trials. Before doing that, however, it will be helpful to learn more about the purpose of clinical trials and how they are carried out.</p>
              <p>As you read, think about how different aspects of clinical trials relate to your ideas about experimental design.</p>
              <p>Your teacher will tell you which option to choose to learn about clinical trials.</p>

              <div className="flex flex-col lg:flex-row">
                <Button
                  action="secondary"
                  className="m-2"
                  color="purple"
                  href="/middle-school/lesson-2/step-5a"
                  title="Option 1"
                >
                  Option 1
                </Button>
                <Button
                  action="secondary"
                  className="m-2"
                  color="purple"
                  href="/middle-school/lesson-2/step-5b"
                  title="Option 2"
                >
                  Option 2
                </Button>
                <Button
                  action="secondary"
                  className="m-2"
                  color="purple"
                  href="/middle-school/lesson-2/step-5c"
                  title="Option 3"
                >
                  Option 3
                </Button>
              </div>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>The reading about clinical trials will help prepare students for analyzing real clinical trials later in the lesson. Students should see some similarities (and some differences) between their list about experimental design and clinical trials.</p>
              <p>For the reading, you can choose from three versions. Each reading includes the same information but allows you to select a version most appropriate for your students. Tell your students which reading to choose.</p>

              <UnorderedList>
                <li><strong>Option 1</strong> uses the link to the National Institutes of Health (NIH) website. This is the information that the NIH publishes to inform the public about important health and health research. This version is included because this is the information they should be looking for on the Internet if they want to learn about clinical trials. This option is for students who can read and understand scientific information without help.</li>
                <li><strong>Option 2</strong> uses the same information from the NIH website. In this version, students can use a tool to highlight the most important ideas from the text. Students can then compare their highlighting with ours. This option is for students who can read scientific information but may need some help sorting through the text to identify the most important sections. Encourage students to work in teams of 2–3 so they can discuss as a team what should be highlighted.</li>
                <li><strong>Option 3</strong> is a simplified reading. This option is for students who may find the text from the NIH website to be too challenging.</li>
              </UnorderedList>

              <Accordion
                className="mt-6 mb-4"
                headerText="Note To Teachers"
                open={true}
              >
                <p>For students using Option 1 or Option 2, you may want to point out that their instructions include specific sections to read and focus on. They can skip other sections of these readings.</p>
              </Accordion>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
